<template>
  <page-layout
    :title="$t('user_management.manage_app_users_page.title')"
    :sub-title="$t('user_management.manage_app_users_page.sub_title')"
    :fixed-footber="true"
  >
    <template #header-actions>
      <el-dropdown trigger="click" class="import-users-dropdown">
        <base-button
          prepend-icon="ri-user-received-line"
          append-icon="ri-arrow-down-s-line"
          :label="$t('user_management.manage_app_users_page.action_button.import_users')"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="$router.push({ name: 'ImportUsers' })">
            <i class="ri-user-received-line"></i>
            {{ $t('user_management.manage_app_users_page.action_button.import_new_users') }}
          </el-dropdown-item>
          <el-dropdown-item
            @click.native="
              $router.push({
                name: 'ImportUsers',
                query: {
                  updateExistingUsers: true
                }
              })
            "
          >
            <i class="ri-user-add-line"></i>
            {{ $t('user_management.manage_app_users_page.action_button.update_users') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <base-button
        type="primary"
        prepend-icon="ri-add-line"
        :label="$t('user_management.manage_app_users_page.action_button.add_app_user')"
        @click.native="$router.push({ name: 'AddAppUser' })"
      />
    </template>
    <div class="filters">
      <el-form ref="form" :inline="true" :model="form" @submit.prevent.native="fetchData">
        <base-input
          class="search"
          ref="search"
          prop="search"
          v-model.trim="form.search"
          name="search"
          auto-complete="off"
          :placeholder="$t('user_management.manage_app_users_page.form_filter.input_search.label')"
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
        <el-form-item>
          <el-checkbox v-model="form.inactive">
            {{ $t('user_management.manage_app_users_page.form_filter.checkbox_inactive.label') }}
          </el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="showNegativeBudgets">
            {{
              $t('user_management.manage_app_users_page.form_filter.checkbox_negative.label', {
                number: countUsersWithNegativeBudget
              })
            }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>

    <!-- Default users table -->
    <el-table
      id="appUsersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="rowClick"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>

      <el-table-column
        class-name="text-bold"
        prop="firstname"
        show-overflow-tooltip
        :label="$t('general.label.first_name')"
        min-width="110"
        sortable="sortColumn"
      >
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="lastname"
        show-overflow-tooltip
        :label="$t('general.label.last_name')"
        min-width="110"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="login"
        show-overflow-tooltip
        sortable="sortColumn"
        min-width="130"
        :label="$t('user_management.manage_app_users_page.table_header.login')"
      >
      </el-table-column>

      <el-table-column
        v-if="$auth.isOspAdmin()"
        prop="extId"
        show-overflow-tooltip
        :label="$t('general.label.extid')"
        min-width="100"
      ></el-table-column>
      <template v-if="tableData.length">
        <el-table-column
          v-for="budget in budgetTypesList"
          :key="budget.budgetType"
          min-width="130"
          :label="getBudgetLabel(budget.budgetType)"
        >
          <template slot-scope="{ row }">
            {{ findBudgetAmount(row.budgets, budget.budgetType) }}
          </template>
        </el-table-column>
      </template>

      <el-table-column
        prop="startActivationTime"
        sortable="sortColumn"
        show-overflow-tooltip
        :label="$t('user_management.manage_app_users_page.table_header.active_since')"
        width="140"
      >
        <template slot-scope="scope">
          {{ $dateFormat(scope.row.startActivationTime) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="active"
        :label="$t('user_management.manage_app_users_page.table_header.active')"
        width="100"
      >
        <template slot-scope="scope">
          <i class="activity-status" :class="scope.row.active ? 'active ri-check-line' : 'inactive ri-close-line'"> </i>
        </template>
      </el-table-column>
    </el-table>

    <template #footer v-if="totalPages > 1">
      <base-pagination v-model="pagination.page" :pages="totalPages" />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { debounce, get, flatten, uniq, orderBy } from 'lodash'
import { mapGetters } from 'vuex'
import UserRepository from '@/app/repositories/UserRepository'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import Role from '@/app/services/RolesService/Role'

const defaultSort = { order: 'ascending', prop: 'firstname' }

const userRepository = new UserRepository()

export default {
  name: 'ManageAppUsers',
  components: {
    PageLayout
  },
  mixins: [TableLoader, CancelRequest],
  data () {
    return {
      dirtyData: {
        content: [],
        totalPages: 1
      },
      loading: false,
      showNegativeBudgets: false,
      form: {
        search: '',
        inactive: false
      },
      pagination: {
        page: 0,
        size: 10,
        sort: defaultSort
      },
      requestSource: null,
      usersWithNegativeBudget: [],
      countUsersWithNegativeBudget: 0
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.showNegativeBudgets || this.fillTable()
    }, 200),
    'pagination.sort': debounce(function () {
      this.showNegativeBudgets || this.fillTable()
    }, 200),
    'form.inactive': debounce(function () {
      this.pagination.page = 0
      this.fillTable()
      this.fetchCountWithNegativeBudget()
    }, 200),
    'form.search': debounce(function () {
      this.pagination.page = 0
      this.fillTable()
      this.fetchCountWithNegativeBudget()
    }, 700),
    showNegativeBudgets: debounce(function () {
      this.pagination.page = 0
      this.fillTable()
      // this.fetchCountWithNegativeBudget()
    }, 10)
  },
  computed: {
    ...mapGetters({
      langDefault: 'i18n/langDefault',
      email: 'user/email',
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId',
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return get(this.corporateAccount, 'budgetTypes', [])
    },
    filters () {
      return {
        active: !this.form.inactive,
        activationStatusIn: !this.form.inactive ? ['ACTIVE'] : ['END_OF_LIFE', 'INACTIVE'],
        roles: [Role.OSPUSER],
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: {
            sortOrders: [
              {
                direction: (this.pagination.sort.order || defaultSort.order).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        },
        search: this.form.search || undefined,
        corporateAccountOspId: this.corporateAccountOspId
      }
    },
    tableData () {
      if (this.showNegativeBudgets) {
        return orderBy(
          this.usersWithNegativeBudget,
          [this.pagination?.sort?.prop],
          [this.pagination?.sort?.order === 'descending' ? 'desc' : 'asc']
        ).slice(this.pagination.page * this.pagination.size, (this.pagination.page + 1) * this.pagination.size)
      }
      return this.dirtyData.content
    },
    totalPages () {
      try {
        if (this.showNegativeBudgets) {
          return Math.ceil(this.usersWithNegativeBudget.length / this.pagination.size)
        }
        return this.dirtyData.totalPages || 1
      } catch (e) {
        return 1
      }
    },
    budgetTypesList () {
      let allUserBudgetTypes = this.tableData.map(user => user.budgets.map(({ budgetType }) => budgetType))
      allUserBudgetTypes = uniq(flatten(allUserBudgetTypes))
      return this.budgetTypes.filter(({ budgetType }) => allUserBudgetTypes.includes(budgetType))
    }
  },
  methods: {
    getBudgetLabel (budgetType) {
      const type = this.budgetTypes.find(i => i.budgetType === budgetType)
      return this.$trans(type.budgetTypeName)
    },
    fillTable () {
      return this.showNegativeBudgets ? this.fetchUsersWithNegativeBudget() : this.fetchData()
    },
    async fetchData () {
      this.TableLoader_show()
      try {
        await userRepository.findByAny(this.filters, {
          cancelToken: this.CancelRequest_token(),
          onSuccess: response => {
            this.dirtyData = response
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    async fetchUsersWithNegativeBudget () {
      this.TableLoader_show()
      try {
        await userRepository.findWithNegativeBudget(this.filters, {
          onSuccess: response => {
            this.usersWithNegativeBudget = response
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    fetchCountWithNegativeBudget () {
      return userRepository.countWithNegativeBudget(this.filters, {
        onSuccess: response => {
          this.countUsersWithNegativeBudget = response
        }
      })
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'AppUserDetailView',
        params: { userOspId: ospId }
      })
    },
    rowClick ({ ospId }) {
      this.$router.push({
        name: 'AppUserBudgetView',
        params: { userOspId: ospId }
      })
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    findBudgetAmount (budgets, budgetType) {
      const targetBudget = budgets.find(budget => budget.budgetType === budgetType)
      if (targetBudget && targetBudget.limited) {
        return this.$currency(targetBudget.currentAmount)
      }
      if (targetBudget && !targetBudget.limited) {
        return this.$t('manage_user_table.label.unlimited')
      }
      return '-'
    }
  },
  mounted () {
    this.fillTable()
    this.fetchCountWithNegativeBudget()
    // this.fetchUsersWithNegativeBudget()
  }
}
</script>

<style lang="scss" scoped>
.import-users-dropdown {
  margin-right: 10px;
}
</style>
